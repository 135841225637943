.cc-window {
    .base-box-shadow();

    .cc-link {
        padding: 0;
        margin-bottom: 1.5em !important;
    }

    .cc-highlight {
        .cc-btn {
            border-radius: 100px;
        }

        .cc-deny {
            background-color: rgba(0,0,0,0.05) !important;

            &:hover, &:focus {
                background-color: rgba(0, 0, 0, 0.075) !important;
            }
        }
    }

    .cc-compliance {
        padding-top: 1.5em;
        border-top: 1px solid rgba(0,0,0,0.1);
    }
}

.cc-revoke {
    display: none !important;
}
