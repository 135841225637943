* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 62.5%;
}

body {
    min-width: @screen-xxs;
    overflow-x: hidden;

    @media (min-width: @screen-md-min) {
        padding-top: @top-nav-height; // navigation height

        &.admin-toolbar-visible {
            padding-top: (@top-nav-height + @admin-toolbar-height);
        }
    }
}

.page-main {
    @media (max-width: @screen-sm-max) {
        // Don't add the calculated min height (javascript calculation) on mobile screen sizes
        // since the navigation is not visible anymore
        min-height: auto !important;
    }
}

.container {
    &.large {
        @media (min-width: @screen-xl-min) {
            width: @container-xl;
        }

        @media (min-width: @screen-xxl-min) {
            width: @container-xxl;
        }
    }

    &.padded-left {
        @media (min-width: @screen-md-min) {
            padding-left: (@sidebar-width-smaller + (@grid-gutter-width * 2));
        }

        @media (min-width: @screen-lg-min) {
            padding-left: (@sidebar-width + (@grid-gutter-width * 2));
        }
    }

    &.padded-right {
        @media (min-width: @screen-xl-min) {
            padding-right: (@sidebar-width + (@grid-gutter-width * 2));
        }
    }
}

section,
.section {
    padding: @section-padding-base 0;

    @media (min-width: @screen-sm-min) {
        padding: (@section-padding-base * 2) 0;
    }

    &.brand-gray-lighter {
        background: @brand-gray-lighter;
    }

    &.brand-gray-light {
        background: @brand-gray-light;
    }

    &.brand-yellow-light {
        background: @brand-yellow-light;
    }

    &.plain + .plain,
    &.brand-gray-light + .brand-gray-light,
    &.brand-gray-lighter + .brand-gray-lighter {
        padding-top: 0;
    }

    &.alertify-logs,
    &.alertify-hidden {
        padding: 0;
    }

    &.section-condensed {
        padding: (@section-padding-base / 2) 0;

        @media (min-width: @screen-sm-min) {
            padding: @section-padding-base 0;
        }
    }

    .section-title {
        margin-top: 0;
        margin-bottom: @grid-gutter-width;
    }
}

.svg-icon {
    display: inline-block;
    vertical-align: middle;
    height: @line-height-computed;
    width: @line-height-computed;
}

.flex-row {
    .row();
    display: flex;
    flex-flow: row wrap;

    .flex-col {
        padding-left: (@grid-gutter-width / 2);
        padding-right: (@grid-gutter-width / 2);
        flex: 0 auto;
        flex-basis: 100%;
        max-width: 100%;

        &.col-25 {
            flex-basis: 25%;
            max-width: 25%;
        }

        &.col-33 {
            flex-basis: 33.333333%;
            max-width: 33.333333%;
        }

        &.col-50 {
            flex-basis: 50%;
            max-width: 50%;
        }

        &.col-66 {
            flex-basis: 66.666666%;
            max-width: 66.666666%;
        }

        &.col-75 {
            flex-basis: 75%;
            max-width: 75%;
        }
    }
}

.base-box-shadow {
    box-shadow: 0px 0px 25px rgba(0,0,0,.12);
}

.google-map {
    height: 300px;

    body.contact & {
        margin-bottom: @grid-gutter-width;
    }
}

.banner-image {
    width: 100%;
    height: auto;
}

.badge.medicine {
    .label();
}

.text-error {
    color: @brand-danger;
}
