.product-grid {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: 0 -(@grid-gutter-width / 2);

    .product-grid-item {
        display: flex;
        padding: (@grid-gutter-width / 2);
        flex: 0 1 50%;
        max-width: 50%;
    }

    .product-card {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        padding: @grid-gutter-width;
        font-weight: 600;
        position: relative;

        .badges {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transform: translate(0%, -50%);
            text-align: center;
            z-index: 2;
        }

        > a {
            color: @text-color;
            transition: all .1s;

            &:hover, &:focus {
                text-decoration: none;
                color: @link-hover-color;
                opacity: 0.8;
            }
        }
    }

    .product-image-wrap {
        margin: (@grid-gutter-width / 2) auto;
        max-width: 180px;
    }

    .product-name {
        text-align: center;
        padding: (@grid-gutter-width / 3) (@grid-gutter-width / 2);
    }

    .price-line {
        margin-top: (@grid-gutter-width / 2);
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;

        .price-wrap {
            flex: 1 1 auto;

            .price {
                font-size: @font-size-h3;
            }

            .save-amount {
                font-size: @font-size-small;
            }
        }

        .basket-form {
            flex: 0 0 auto;

            button {
                @media (max-width: @screen-xs-max) {
                    .btn-sm();
                }
            }
        }
    }
}

.supply-disruption {
    margin-top: 5px;
    color: @brand-danger;
    font-style: italic;
    font-weight: 700;
}
