.footer-section {
    padding: @section-padding-base 0;

    .footer-title {
        margin-top: 10px;
        margin-bottom: @grid-gutter-width;
        color: @text-color;
    }

    .footer-subtitle {
        margin: 0;
        font-size: @font-size-large;
        line-height: @line-height-base;
        color: @text-color;
    }

    &.footer-top {
        color: rgba(0,0,0,.65);

        a {
            color: rgba(0,0,0,.65);
        }

        .footer-top-column {
            @media (min-width: @screen-xs-min) {
                flex-basis: 50%;
            }

            @media (min-width: @screen-md-min) {
                flex: 1 0 0%;
            }
        }

        .shop-logo {
            padding: 10px;
            border-color: rgba(0,0,0,.1);
        }

        .fb-like {
            margin-bottom: (@grid-gutter-width / 2);
        }
    }

    &.footer-bottom {
        color: rgba(0,0,0,.65);

        a {
            color: rgba(0,0,0,.65);
            font-weight: 600;

            &:hover, &:focus {
                color: @link-color;
            }
        }

        .sanco-logo-wrap {
            flex-wrap: nowrap;
            margin-top: @grid-gutter-width;

            @media (min-width: @screen-sm-min) {
                justify-content: flex-end;
                margin-top: 0;
            }

            .flex-col {
                flex: 0 1 auto;
                max-width: 200px;
            }

            img {
                width: 100%;
                max-width: 200px;
                height: auto;
            }
        }

        .bottom-links {
            padding: 0;
            margin-bottom: 0;
            list-style: none;

            @media (max-width: @screen-xs-max) {
                margin-bottom: @grid-gutter-width;
                padding-bottom: @grid-gutter-width;
                border-bottom: 1px solid @border-color;
            }

            li {
                display: block;

                a {
                    display: block;
                    padding: 3px 0;
                }
            }
        }
    }
}
