.prescription-content {
    .start-prescription-order {
        margin-top: @grid-gutter-width;
        margin-bottom: (@grid-gutter-width / 2);
    }

    h2 {
        margin-top: @grid-gutter-width;
    }
}

.prescription-how-to-list {
    margin-top: @grid-gutter-width;
    padding: 0;
    list-style: none;
    counter-reset: list-counter;

    li {
        display: block;
        position: relative;
        padding: (@grid-gutter-width / 2) 0 (@grid-gutter-width / 2) 50px;

        &:before {
            content: counter(list-counter);
            position: absolute;
            left: 0;
            top: 16px;
            counter-increment: list-counter;
            color: @text-muted;
            font-size: 30px;
            font-weight: 800;
            line-height: 1;
            width: 40px;
            text-align: center;
        }
    }

    strong {
        display: block;
        font-size: @font-size-large;
        line-height: 1.1;
        margin-bottom: 3px;
    }
}
