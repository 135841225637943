.masonry-grid {
    .row();
}

// Define default grid sizes
.masonry-grid-item,
.masonry-grid-sizer {
    width: 100%;

    @media (min-width: @screen-xs-min) {
        width: 50%;
    }

    @media (min-width: @screen-md-min) {
        width: 33.333333%;
    }
}

.masonry-grid-item {
    float: left;
    padding: (@grid-gutter-width / 2);
}
