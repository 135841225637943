.label {
    font-size: @font-size-small;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
    color: @white;
    background: lighten(@text-color, 15%);
    padding: 2px 10px;
    border-radius: @border-radius-small;

    &.label-orange {
        background: @brand-orange;
    }
}
