.articles {
    .single-article {
        .clearfix();
        border-top: 1px solid @border-color;
        padding-top: @grid-gutter-width;
        margin-top: @grid-gutter-width;

        &:before {
            content: "";
            display: block;
            position: relative;
            width: 0;
            height: (@top-nav-height + (@grid-gutter-width / 2));
            margin-top: -(@top-nav-height + (@grid-gutter-width / 2));
            z-index: -9999;
        }

        .article-image {
            max-width: 100%;

            .thumbnail {
                margin-bottom: (@grid-gutter-width / 2);
            }

            @media (min-width: @screen-xs-min) {
                max-width: 220px;
                margin: 0 @grid-gutter-width 0 0;
                float: left;
            }
        }

        .article-title {
            margin-top: 0;
            display: block;
            margin-bottom: (@grid-gutter-width / 3);
        }

        .article-date {
            color: darken(@brand-gray, 20%);
            margin-bottom: (@grid-gutter-width / 3);
            font-weight: bold;
            font-size: @font-size-small;
        }
    }
}
