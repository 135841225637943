.dropdown-menu {
    border-color: @border-color;
    box-shadow: 0px 0px 20px rgba(0,0,0,.12);
}

.lead {
    font-size: @font-size-large;
    font-weight: normal;
    line-height: @line-height-base;
    margin-bottom: (@grid-gutter-width / 2);
}

.pagination {
    font-weight: 700;

    > li > a {
        color: darken(@brand-gray, 10%);
        border-color: darken(@brand-gray, 10%);
        margin-left: -2px;

        &:hover, &:focus {
            color: darken(@brand-gray, 10%);
            border-color: darken(@brand-gray, 10%);
            background: @brand-gray-light;
        }
    }

    > li.active > a {
        background: darken(@brand-gray, 10%);
        color: @white;
        border-color: darken(@brand-gray, 10%);

        &:hover, &:focus {
            color: @white;
            background: darken(@brand-gray, 10%);
            border-color: darken(@brand-gray, 10%);
        }
    }

    > li.next > a,
    > li.previous > a {
        padding-left: 10px;
        padding-right: 10px;
    }

    .svg-icon {
        display: block;
        width: 13px;
        height: 23px;
        fill: darken(@brand-gray, 10%);
    }
}

.tooltip {
    font-weight: 600;
}

.alert {
    font-weight: 600;
}

.well {
    background: @brand-gray-lighter;
    padding: (@grid-gutter-width / 2);
    border: 1px solid @border-color;
    border-radius: @border-radius-base;
    box-shadow: none;

    @media (min-width: @screen-sm-min) {
        padding: @grid-gutter-width;
    }
}
