.shipping-extra {
    display: none;
    padding: 0 (@grid-gutter-width / 2) (@grid-gutter-width / 2) @grid-gutter-width*1.5;

    &.errors {
        select {
            border-color: @brand-danger;
        }
    }

    .schenker-search-field,
    .itella-search-field {
        .form-control;
        width: 80px;
    }

    .schenker-search-button,
    .itella-search-button {
        .btn;
        .btn-primary;
        .btn-sm;
    }

    .schenker-select,
    .itella-select {
        .form-control;

        @media (min-width: @screen-sm-min) {
            width: 60%;
        }
    }

    .schenker-search-field,
    .schenker-search-button,
    .schenker-select,
    .itella-search-field,
    .itella-search-button,
    .itella-select {
        display: inline-block;
        vertical-align: middle;
        margin: 10px 10px 10px 0;
    }

    .help {
        display: block;
        font-style: italic;
    }
}

.active {
    .shipping-extra {
        display: block;
    }
}

&.show-optional .required-marker {
    display: none;
}

.basket-free-shipping-box {
    margin: 10px 0 20px 0;
    padding: 10px 10px 10px 40px;
    background: @brand-gray-lighter;
    position: relative;
    border-radius: @border-radius-base;
    min-height: 40px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    @media (min-width: @screen-sm-min) {
        min-height: 80px;
        padding: 20px 20px 20px 80px;
    }

    .icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: @text-muted;

        @media (min-width: @screen-sm-min) {
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
        }

        .fa-truck {
            font-size: 20px;

            @media (min-width: @screen-sm-min) {
                font-size: 40px;
            }
        }

        &.success {
            @media (min-width: @screen-sm-min) {
                &:before {
                    position: absolute;
                    z-index: 2;
                    content: @fa-var-check;
                    font-family: FontAwesome;
                    color: #fff;
                    margin-right: 3px;
                    right: 0;
                    transform: translate(-3px, 4px);
                }
            }

            .fa-truck {
                color: fade(@brand-success, 75%);
            }
        }
    }

    h3 {
        color: rgba(0,0,0,0.6);
        margin: 0;
        font-size: @font-size-base;

        @media (min-width: @screen-sm-min) {
            font-size: @font-size-h4;
        }
    }

    .extra-info {
        margin-top: 0.5rem;
        color: @text-muted-dark;

        @media (max-width: @screen-xs-max) {
            font-size: @font-size-small;
        }
    }
}
