.required-marker {
    display: inline-block;
    margin-left: 3px;
    color: @brand-danger;
    font-weight: 800;
}

.form-group {
    &.auto-width {
        .form-control {
            width: auto;
        }
    }

    label {
        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                display: block;
                padding: 2px 0;
            }

            label {
                padding: 0;
            }
        }
    }
}
