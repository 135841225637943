.basket-table {
    margin-bottom: (@grid-gutter-width / 2);

    @media (max-width: @screen-xs-max) {
        display: flex;
        flex-flow: column wrap;

        thead {
            display: none;
        }

        td {
            display: block;
        }

        tfoot {
            order: 2;
            display: block;

            > tr {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                border-top: 3px solid @border-color;
                border-bottom: 2px solid @border-color;
                padding: 10px 0;

                .update-quantities {
                    flex-basis: 100%;
                    margin-bottom: 25px;
                }

                .total {
                    font-size: @font-size-large;
                    align-self: center;

                    .price {
                        font-size: @font-size-h3;
                    }
                }
            }
        }

        tbody {
            order: 1;
            display: block;

            > tr {
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                position: relative;
                border-top: 1px solid @border-color;
                padding: 10px 45px 10px 60px;

                &.method-line {
                    > td {
                        width: 100%;
                        text-align: left;
                    }
                }

                &.no-image {
                    padding-left: 0;
                }

                > .image {
                    position: absolute;
                    top: 20px;
                    left: 0;
                    width: 45px;

                    > a {
                        display: block;
                        margin-bottom: 5px;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .label {
                        font-size: 9px;
                        padding: 1px 4px;
                    }
                }

                > .delete-column {
                    position: absolute;
                    right: 0;
                    top: 15px;
                    width: 40px;
                    text-align: right;
                }

                .product-details {
                    flex-basis: 100%;
                }

                .unit-price,
                .quantity,
                .total-price {
                    flex-basis: 100%;
                    align-self: center;
                    text-align: left;
                }

                .unit-price {
                    margin-bottom: 10px;
                }

                .quantity {
                    margin-bottom: 10px;

                    label {
                        font-size: @font-size-small;
                        color: @text-muted-dark;
                        margin-right: 2px;
                        font-weight: normal;
                    }
                }

                .total-price {
                    span {
                        white-space: nowrap;
                    }
                }
                
                > .campaign-field {
                    input {
                        float: right;
                        height: 38px;
                        padding: 0px 15px 0px 15px;
                        line-height: 38px;
                        border: 2px solid darken(@brand-gray, 20%);
                        border-radius: 38px;
                        font-weight: 600;
                        outline: none !important;
            
                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: darken(@brand-gray, 60%);
                        }
            
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: darken(@brand-gray, 60%);
                        }
            
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: darken(@brand-gray, 60%);
                        }
            
                        &:-moz-placeholder { /* Firefox 18- */
                            color: darken(@brand-gray, 60%);
                        }
                    }
                }

                .add-campaign {
                    text-align: right;
                    width: 10%;
                }

                .remove-campaign {
                    text-align: right;
                    > .btn-primary {
                        border-color: @brand-red;
                        background: @brand-red;
                        color: #fff;
                    
                        &:hover, &:focus {
                            background: darken(@brand-red, 10%);
                            border-color: darken(@brand-red, 10%);
                            color: #fff;
                        }
                    
                        .svg-icon {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: @screen-sm-min) {
        thead {
            > tr > th {
                border-bottom: 3px solid @border-color;
                padding: 15px 10px;
                font-size: @font-size-large;
            }
        }

        tbody {
            > tr {
                > td {
                    border-top: 1px solid @border-color;
                    padding: 15px 10px;
                }
                
                > .campaign-field {
                    input {
                        float: right;
                        height: 38px;
                        padding: 0px 15px 0px 15px;
                        line-height: 38px;
                        border: 2px solid darken(@brand-gray, 20%);
                        border-radius: 38px;
                        font-weight: 600;
                        outline: none !important;
            
                        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                            color: darken(@brand-gray, 20%);
                        }
            
                        &::-moz-placeholder { /* Firefox 19+ */
                            color: darken(@brand-gray, 20%);
                        }
            
                        &:-ms-input-placeholder { /* IE 10+ */
                            color: darken(@brand-gray, 20%);
                        }
            
                        &:-moz-placeholder { /* Firefox 18- */
                            color: darken(@brand-gray, 20%);
                        }
                    }
    
                }

                .add-campaign {
                    text-align: right;
                    width: 10%;
                }

                .remove-campaign {
                    text-align: right;
                    > .btn-primary {
                        border-color: @brand-red;
                        background: @brand-red;
                        color: #fff;
                    
                        &:hover, &:focus {
                            background: darken(@brand-red, 10%);
                            border-color: darken(@brand-red, 10%);
                            color: #fff;
                        }
                    
                        .svg-icon {
                            fill: #fff;
                        }
                    }
                }
            }


            .delete-column,
            .image {
                width: 1%;
            }

            .image {
                @media (min-width: @screen-lg-min) {
                    padding-left: 25px;
                    padding-right: 20px;
                }

                > a {
                    display: block;
                    margin-bottom: 5px;
                }

                .label {
                    font-size: 9px;
                    padding: 1px 4px;
                }
            }

            .quantity label {
                display: none;
            }
        }

        tfoot {
            > tr > td {
                border-top: 3px solid @border-color;
                border-bottom: 2px solid @border-color;
                padding: 15px 10px;

                &.total {
                    font-size: @font-size-large;
                    font-weight: 600;
                }
            }

            .line-total.price {
                color: @brand-primary;
                font-size: @font-size-h3;
                white-space: nowrap;

                .taxful:not([style*="display: none"]) + .taxless {
                    // Only applied to taxless price if taxful price is not hidden.
                    font-size: @font-size-base;
                    color: @text-muted;
                }
            }
        }

        .price-label {
            display: none;
        }
    }

    tbody {
        .quantity .form-control {
            width: auto;
            display: inline-block;
        }

        .product-name {
            font-weight: 600;
        }

        .delete-column > .btn > span {
            margin-left: 2px;

            @media (max-width: @screen-md-max) {
                display: none;
            }
        }

        .product-extra-details {
            color: @text-muted-dark;
            font-size: @font-size-small;
        }
    }

    .price {
        font-weight: 800;

        .taxful:not([style*="display: none"]) + .taxless {
            // Only applied to taxless price if taxful price is not hidden.
            font-size: @font-size-small;
            color: @text-muted;
        }

        .regular-price {
            font-size: @font-size-small;
            color: @text-muted;
            text-decoration: line-through;
        }
    }

    .line-total.price {
        color: @brand-primary;
        font-size: @font-size-large;

        .taxful:not([style*="display: none"]) + .taxless {
            // Only applied to taxless price if taxful price is not hidden.
            font-size: @font-size-small;
            color: @text-muted;
        }
    }

    .price-label {
        font-weight: normal;
        font-size: @font-size-small;
        color: @text-muted-dark;
    }

    .regular-price {
        font-weight: normal;
        font-size: @font-size-small;
        color: @text-muted-dark;
    }

}
