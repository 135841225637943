.main-nav-wrapper {
    .container();
    .container.large();

    @media (max-width: @screen-sm-max) {
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        z-index: -1;
        overflow: hidden;
        opacity: 0;
        transition: all .3s ease-out;
        width: auto !important; // undo .container
        padding: 0 !important; // undo .container

        body.admin-toolbar-visible & {
            top: @admin-toolbar-height;
        }

        body.mobile-menu-open & {
            visibility: visible;
            z-index: @zindex-navbar;
            opacity: 1;
            overflow: visible;
        }
    }

    @media (min-width: @screen-md-min) {
        position: relative;
        height: 0;
        overflow: visible;
    }
}

.main-navigation {
    background: #fff;
    .base-box-shadow();
    padding: (@grid-gutter-width / 1.5) @grid-gutter-width;
    width: @sidebar-width-smaller;

    @media (max-width: @screen-sm-max) {
        border-bottom-left-radius: @border-radius-base;
        padding-top: @top-nav-height;
    }

    @media (max-width: @screen-xs-max) {
        padding-top: 65px;
    }

    @media (min-width: @screen-md-min) {
        border-radius: @border-radius-base;
        position: absolute;
        left: (@grid-gutter-width / 2);
        top: -@grid-gutter-width;
        z-index: (@zindex-navbar - 1);
    }

    @media (min-width: @screen-lg-min) {
        width: @sidebar-width;
    }

    &.no-banner {
        top: 40px;
        @media (min-width: @screen-md-min) {
            top: 40px;
        }
    }

    .mobile-language-chooser {
        margin-bottom: (@grid-gutter-width / 2);

        .language-dropdown {
            display: block;
            width: 100%;
        }

        .dropdown-menu {
            width: 100%;
        }

        @media (min-width: @screen-md-min) {
            display: none;
        }
    }

    .mobile-user-nav {
        margin-bottom: (@grid-gutter-width / 2);

        .btn {
            display: block;
            width: 100%;
        }

        @media (min-width: @screen-md-min) {
            display: none;
        }
    }

    .mobile-hr {
        @media (min-width: @screen-md-min) {
            display: none;
        }
    }

    .prescription-btn {
        margin-bottom: (@grid-gutter-width / 1.5);

        @media (min-width: @screen-lg-min) {
            .btn-lg;
        }

        .svg-icon {
            vertical-align: bottom;
            margin-left: 3px;
            height: @line-height-computed;
            width: @line-height-computed;

            @media (max-width: @screen-md-max) {
                width: 18px;
                height: 18px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: block;

            &.active {
                > ul {
                    display: block;
                }

                > a {
                    color: @link-color !important;
                    font-weight: 700;
                }
            }

            &.ancestor {
                > ul {
                    display: block;
                }

                > a {
                    font-weight: 700;
                }
            }

            &.divider {
                border-top: 1px solid @border-color;
                margin: (@grid-gutter-width / 2) 0;
            }

            a {
                color: @text-color;
                font-weight: 600;
                padding: 8px 0;
                display: block;
                text-decoration: none;
                line-height: 1.1;
                transition: all .1s;

                &:hover, &:focus {
                    color: @brand-green;
                }
            }

            ul {
                display: none;
                padding-left: 15px;

                li {
                    a {
                        color: lighten(@text-color, 20%);

                        &:hover, &:focus {
                            color: @brand-green;
                        }
                    }
                }
            }
        }

        li.level-2 {
            a {
                font-size: @font-size-small !important;
                color: @text-muted-dark;
            }

            &.active > a {
                color: @brand-green;
            }
        }
    }

    .main-navigation-menu-items > ul {
        li.toggleable-submenu {
            > a {
                position: relative;
                margin-left: -8px;
                padding-left: 20px;
            }

            &.menu-open {
                .menu-toggler-icon:after {
                    display: none;
                }
            }

            .menu-toggler-icon {
                position: absolute;
                left: 0;
                top: 8px;
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid @brand-green;
                border-radius: 50%;

                &:before,
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    background: @brand-green;
                }

                &:before {
                    width: 6px;
                    height: 2px;
                    left: 3px;
                    top: 5px;
                }

                &:after {
                    height: 6px;
                    width: 2px;
                    left: 5px;
                    top: 3px;
                }
            }
        }

        > li > ul {
            display: none;
            padding-left: 15px;
            border-left: 2px solid @brand-green;
            margin: 5px 0 5px 15px;
            position: relative;

            &:before,
            &:after {
                content: "";
                width: 4px;
                height: 4px;
                background: @brand-green;
                display: block;
                border-radius: 50%;
                position: absolute;
                left: -3px;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }


            li a {
                font-size: 13px;
                padding: 7px 0;
            }
        }
    }
}
