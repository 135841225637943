.btn {
    background: transparent;
    color: @text-color;
    padding: 6px 16px;
    font-size: 13px;
    border: 2px solid transparent;
    display: inline-block;
    border-radius: 30px;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    transition: all .15s;

    &:hover, &:focus {
        text-decoration: none;
        outline: 0 !important;
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.btn-block {
    width: 100%;
    display: block;
}

.btn-sm {
    padding: 4px 12px;
    font-size: @font-size-small;
}

.btn-xs {
    padding: 3px 7px;
    font-size: @font-size-small;
}

.btn-lg {
    padding: 8px 20px;
    font-size: 16px;
}

.btn-orange {
    border-color: @brand-orange;
    background: @brand-orange;
    color: #fff;

    &:hover, &:focus {
        background: darken(@brand-orange, 10%);
        border-color: darken(@brand-orange, 10%);
        color: #fff;
    }

    .svg-icon {
        fill: #fff;
    }
}

.btn-gray {
    border-color: darken(@brand-gray, 15%);
    background: darken(@brand-gray, 15%);
    color: #fff;

    &:hover, &:focus {
        background: darken(@brand-gray, 25%);
        border-color: darken(@brand-gray, 25%);
        color: #fff;
    }

    .svg-icon {
        fill: #fff;
    }
}

.btn-gray-outline {
    border-color: @brand-gray;
    color: darken(@brand-gray, 20%);

    &:hover, &:focus {
        border-color: darken(@brand-gray, 20%);
        background: darken(@brand-gray, 20%);
        color: #fff;
    }

    .svg-icon {
        fill: darken(@brand-gray, 20%);
    }
}

.btn-green,
.btn-primary {
    border-color: @brand-green;
    background: @brand-green;
    color: #fff;

    &:hover, &:focus {
        background: darken(@brand-green, 10%);
        border-color: darken(@brand-green, 10%);
        color: #fff;
    }

    .svg-icon {
        fill: #fff;
    }
}

.prescription-btn {
    .svg-icon {
        width: 18px;
        height: 18px;
        margin-left: 3px;
        vertical-align: bottom;
    }
}
