.alertify-show,
.alertify-log {
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.320, 1.275); /* easeOutBack */
}

.alertify-hide {
    transition: all 250ms cubic-bezier(0.600, -0.280, 0.735, 0.045); /* easeInBack */
}

.alertify-cover {
    box-shadow: 0 0 500px 0 rgba(0, 0, 0, .8) inset;
    background: rgba(0, 0, 0, .2);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99998;
}

.alertify-hidden {
    top: -50px;
    visibility: hidden;
}

.alertify {
    position: fixed;
    z-index: 99999;
    width: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;

    @media (min-width: @screen-xs-min) {
        width: 500px;
        margin-left: -250px;
        top: 50px;
        left: 50%;
    }

    .alertify-dialog {
        text-align: center;
        background-color: @white;
        padding: @grid-gutter-width;
    }

    .alertify-message {
        .h3();
        margin: 0;
        padding: @grid-gutter-width 0;
    }

    .alertify-buttons {
        .alertify-button {
            .btn();
            .btn-gray-outline();
            margin: (@grid-gutter-width / 2);
        }

        .alertify-button-ok {
            .btn();
            .btn-primary();
        }
    }
}
