.article-lift {
    border: 2px solid @border-color;
    border-radius: @border-radius-base;
    background: #fff;

    .article-image img {
        border-top-left-radius: @border-radius-small;
        border-top-right-radius: @border-radius-small;

        @media (max-width: @screen-xxs-max) {
            width: 100%;
        }
    }

    .article-content {
        padding: (@grid-gutter-width / 2);

        .article-title {
            font-size: @font-size-large;
            line-height: @line-height-base;
            margin: 0 0 (@grid-gutter-width / 3) 0;

            a {
                color: @text-color;
                transition: all .1s;
                display: block;

                &:hover, &:focus {
                    color: @link-color;
                    text-decoration: none;
                }
            }
        }

        .article-summary {
            color: @text-muted-dark;
            font-size: 15px;
        }

        .article-publish-date {
            color: @text-muted;
            font-weight: 600;
            font-size: @font-size-small;
            margin-bottom: (@grid-gutter-width / 3);
        }
    }
}

.btn-external-articles {
    .svg-icon {
        vertical-align: bottom;
        margin-left: 3px;
        width: 18px;
        height: 18px;
    }
}
