body.index .page-main {
    position: relative;
    z-index: 1;

    .mobile-order-prescription {
        position: absolute;
        left: 50%;
        top: 10px;
        transform: translate(-50%, @grid-gutter-width);

        @media (min-width: @screen-xs-min) {
            .btn-lg;

            .svg-icon {
                height: @line-height-computed;
                width: @line-height-computed;
            }
        }

        @media (min-width: @screen-md-min) {
            display: none;
        }

        // More padding to the first section for prescription link in mobile screen sizes
        + section {
            @media (max-width: @screen-sm-max) {
                padding-top: (@grid-gutter-width * 4);
            }
        }
    }

    .product-grid {
        .product-grid-item {
            flex: 0 1 100%;
            max-width: 100%;

            @media (min-width: @screen-xs-min) {
                flex-basis: 50%;
                max-width: 50%;
            }

            @media (min-width: @screen-xl-min) {
                flex-basis: 33.333333%;
                max-width: 33.333333%;
            }
        }
    }
}

.homepage-description {
    h1 {
        margin-top: 0;
        margin-bottom: @grid-gutter-width;
    }
}

.homepage-articles {
    .masonry-grid {
        margin-bottom: @grid-gutter-width;
    }
}

.homepage-main-image {
    margin-bottom: (@grid-gutter-width / 2);

    .main-image {
        width: 100%;
        height: auto;
    }
}
