.breadcrumb {
    list-style: none;
    padding: 0;
    margin: (@grid-gutter-width / 2) 0 @grid-gutter-width 0;

    li {
        display: inline;
        font-weight: 600;
        color: rgba(0,0,0,.5);

        &:after {
            content: "";
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: top;
            margin: 0px 5px;
            opacity: 0.3;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cpath fill='none' d='M-.113 0h31.671v32H-.113z'/%3E%3CclipPath id='a'%3E%3Cpath d='M-.113 0h31.671v32H-.113z'/%3E%3C/clipPath%3E%3Cg clip-path='url%28%23a%29'%3E%3Cpath d='M16.96 15.814l-5.393-4.815a1.291 1.291 0 0 1-.103-1.824l.001-.001a1.292 1.292 0 0 1 1.824-.103l7.553 6.743-7.553 6.744a1.296 1.296 0 0 1-1.824-.103l-.001-.001a1.292 1.292 0 0 1 .103-1.825l5.393-4.815z'/%3E%3C/g%3E%3C/svg%3E");
        }

        &:last-child:after {
            display: none;
        }

        a {
            font-weight: 700;

            @media (max-width: @screen-xs-max) {
                font-size: @font-size-small;
            }
        }
    }
}
