.site-search {
    form {
        position: relative;

        input {
            height: 38px;
            padding: 0px 40px 0px 15px;
            line-height: 38px;
            border: 2px solid darken(@brand-gray, 20%);
            border-radius: 38px;
            width: 100%;
            font-weight: 600;
            outline: none !important;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: darken(@brand-gray, 60%);
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: darken(@brand-gray, 60%);
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: darken(@brand-gray, 60%);
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: darken(@brand-gray, 60%);
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            height: 38px;
            width: 45px;
            background: none;
            border: 0;
            color: darken(@brand-gray, 20%);
            background-position: 50%;
            background-size: 65% auto;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cstyle%3E.a%7Bfill:%2388A9B8;%7D%3C/style%3E%3Cpath fill='none' d='M-.149 0h32v32h-32z'/%3E%3Cpath class='a' d='M16.911 18.477a6.53 6.53 0 0 1-1.395.778 6.569 6.569 0 0 1-2.377.477 6.518 6.518 0 0 1-4.323-1.569 6.547 6.547 0 0 1-1.415-1.707 6.55 6.55 0 0 1-.291-5.92 6.556 6.556 0 0 1 3.06-3.175 6.504 6.504 0 0 1 2.969-.673 6.525 6.525 0 0 1 2.669.604 6.554 6.554 0 0 1 3.171 3.171 6.527 6.527 0 0 1-.641 6.582c2.22 2.144 4.35 4.378 6.532 6.56.124.13.216.282.261.457a1.008 1.008 0 0 1-.901 1.248.996.996 0 0 1-.735-.254c-2.252-2.135-4.399-4.376-6.584-6.579zm-3.904-9.789a4.586 4.586 0 0 0-1.851.419 4.531 4.531 0 0 0-2.307 2.451 4.567 4.567 0 0 0-.247 2.41 4.501 4.501 0 0 0 1.649 2.787 4.496 4.496 0 0 0 2.81.977c.546 0 1.091-.098 1.601-.291a4.54 4.54 0 0 0 2.238-1.837c.273-.436.469-.919.579-1.422a4.508 4.508 0 0 0-1.098-4.044 4.527 4.527 0 0 0-1.414-1.031 4.59 4.59 0 0 0-1.852-.419h-.108z'/%3E%3C/svg%3E%0A");
        }
    }
}

.language-chooser {
    .language-dropdown {
        .btn;
        .btn-gray-outline;

        .caret {
            margin-left: 6px;
        }
    }

    .dropdown-menu {
        left: auto;
        right: 0;

        button.language {
            display: block;
            width: 100%;
            padding: 4px 15px;
            margin: 2px 0;
            background: none;
            border: 0;
            text-align: left;

            &:hover, &:focus {
                background: @brand-gray-light;
            }
        }
    }

    .flag {
        margin-right: 6px;
        display: inline-block;
        width: 16px;
        height: 13px;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: 0 100%;

        &.fi {
            background-image: url(../img/flag-fi.png);
        }

        &.sv {
            background-image: url(../img/flag-sv.png);
        }
    }
}

.nav-basket {
    display: flex;
    align-items: center;
    text-decoration: none !important;

    .basket-icon {
        width: 39px;
        height: 36px;
        display: inline-block;
        position: relative;
        margin-right: 7px;
        background-size: 28px auto;
        background-repeat: no-repeat;
        background-position: 0 50%;
        // shopping bag icon as a bg image
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' clip-rule='evenodd' stroke-linejoin='round' stroke-miterlimit='1.414'%3E%3Cstyle%3E.a%7Bfill:%23565656%7D%3C/style%3E%3Cpath id='shopping' fill='none' d='M.357 0h32v32h-32z'/%3E%3Cpath class='a' d='M25.992 12.5c.103.016.199.035.296.075a1.04 1.04 0 0 1 .619.763l3.207 16.834c.005.033.011.066.014.1a1.044 1.044 0 0 1-.931 1.125c-.033.003-.067.003-.101.005H3.619c-.034-.002-.067-.002-.1-.005a1.044 1.044 0 0 1-.934-1.101c.002-.033.007-.066.011-.099l2.775-16.834a1.042 1.042 0 0 1 .734-.827c.096-.027.189-.036.288-.041h19.496l.103.005zM7.273 14.568L4.84 29.329h23.004l-2.812-14.761H7.273z' fill-rule='nonzero'/%3E%3Cpath class='a' d='M8.832 16.896c-.004-2.982-.018-5.964.001-8.947.016-.824.157-1.64.435-2.417a7.47 7.47 0 0 1 1.744-2.753 7.475 7.475 0 0 1 2.669-1.714 7.594 7.594 0 0 1 2.691-.466c.855.016 1.7.168 2.503.466a7.46 7.46 0 0 1 4.443 4.553c.258.751.391 1.538.405 2.332.019 2.979.004 5.958.001 8.938.266.257.43.612.43 1.005 0 .782-.653 1.418-1.458 1.418-.804 0-1.458-.636-1.458-1.418 0-.385.158-.734.415-.99.009-2.998.05-5.997-.003-8.995a5.457 5.457 0 0 0-.433-1.982 5.398 5.398 0 0 0-1.848-2.279 5.392 5.392 0 0 0-5.088-.592 5.38 5.38 0 0 0-2.889 2.752 5.43 5.43 0 0 0-.487 2.169c-.019 2.973-.004 5.946-.001 8.919.261.257.422.609.422.998 0 .782-.653 1.418-1.458 1.418-.805 0-1.458-.636-1.458-1.418 0-.388.161-.74.422-.997z'/%3E%3C/svg%3E");

        .basket-count {
            background: @brand-green;
            border-radius: 50%;
            width: 21px;
            height: 21px;
            line-height: 20px;
            color: #fff;
            font-weight: 700;
            text-align: center;
            position: absolute;
            top: 2px;
            right: 0px;
            font-size: 13px;
        }
    }

    .basket-total-text {
        color: @text-muted;
        margin-right: 3px;
        font-size: 13px;

        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }

    .basket-total-sum {
        color: #565656;
        font-size: 13px;
        white-space: nowrap;

        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }

    .basket-total-sum-regular {
        color: @text-muted;
        font-size: 10px;
        white-space: nowrap;
        text-decoration: line-through;

        @media (max-width: @screen-sm-max) {
            display: none;
        }
    }
}

.user-menu-dropdown {
    > button {
        i {
            margin-right: 4px;
            font-size: 16px;
        }
    }

    .dropdown-menu {
        padding: (@grid-gutter-width / 2);
        min-width: 260px;
        left: auto;
        right: 0;

        hr {
            margin: (@grid-gutter-width / 3) 0;
        }
    }
}

.mobile-nav-toggler {
    display: flex;
    position: relative;
    align-items: center;
    text-decoration: none !important;

    @media (min-width: @screen-sm-min) {
        height: @top-nav-height;
    }

    body.mobile-menu-open & {
        z-index: (@zindex-navbar + 1);

        .hamburger {
            background: transparent;

            &:before {
                transform: rotate(45deg);
                top: -1px;
                right: 25%;
                width: 13px;
            }

            &:after {
                transform: rotate(-45deg);
                top: -1px;
                right: 25%;
            }
        }
    }

    .hamburger-wrap {
        position: relative;
        width: 40px;

        @media (max-width: @screen-xxs-max) {
            width: 30px;
        }
    }

    .hamburger,
    .hamburger:before,
    .hamburger:after {
        background: @text-color;
        width: 26px;
        height: 3px;
        position: absolute;
        display: block;
        content: "";
        cursor: pointer;
        right: 0;
        top: 50%;
        border-radius: 1px;
        transition: all .2s;
    }

    .hamburger:before {
        top: -8px;
    }

    .hamburger:after {
        width: 13px;
        top: 8px;
        right: 13px;
    }

    .menu-title {
        color: @text-color;
        font-weight: bold;

        @media (max-width: @screen-xxs-max) {
            display: none;
        }
    }
}
