body.basket.payment {
	.payment-providers {
		form {
			margin: 0;
			padding: 5px;
			float: left;
		}
		button {
			width: 120px;
			height: 100px;
			padding: 0;
		}
		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}
