.basket-main-content {
    .basket-title-wrapper {
        display: flex;
        flex-flow: row wrap;

        .section-title {
            flex: 1 1 0%;
            margin-right: @grid-gutter-width;
        }
    }

    .prescription-button-row {
        @media (min-width: @screen-md-min) {
            display: none;
        }

        .prescription-btn {
            .svg-icon {
                width: 16px;
                height: 18px;
                margin-left: 3px;
            }
        }
    }

    .basket-products {
        padding-top: (@grid-gutter-width / 2);
    }

    .basket-order-fields {
        padding: @grid-gutter-width 0;
        margin-top: @grid-gutter-width;

        .checkbox a {
            font-weight: 700;
        }
    }
}
