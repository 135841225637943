.main-header {
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0,0,0,.12);
    z-index: @zindex-navbar;
    display: flex;
    align-items: center;

    @media (min-width: @screen-sm-min) {
        height: @top-nav-height;
    }

    @media (min-width: @screen-md-min) {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;

        body.admin-toolbar-visible & {
            top: @admin-toolbar-height;
        }
    }

    > .container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-flow: row wrap;

        @media (min-width: @screen-sm-min) {
            flex-flow: row nowrap;
        }

        @media (max-width: @screen-sm-max) {
            width: 100%;
            padding: 0;
        }
    }

    .header-logo {
        flex: 1 1 0%;
        padding-right: (@grid-gutter-width / 2);

        @media (max-width: @screen-xxs-max) {
            padding-left: (@grid-gutter-width / 2);
        }

        @media (max-width: @screen-xs-max) {
            padding-top: (@grid-gutter-width / 2);
            padding-bottom: (@grid-gutter-width / 2);
        }

        @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
            padding-left: @grid-gutter-width;
        }

        a {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            text-decoration: none !important;

            img {
                display: block;
                width: 35px;
                height: 35px;
            }

            .divider {
                display: block;
                width: 2px;
                height: 22px;
                background: #e5e5f1;
                margin: 0px 10px;

                @media (max-width: @screen-xs-max) {
                    display: none;
                }

                @media (min-width: @screen-sm-min) {
                    margin: 0px 20px;
                }
            }

            .pharmacy-name {
                color: @text-color;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 600;
                line-height: 1.1;
                letter-spacing: .1em;
                font-family: @font-family-secondary;

                @media (max-width: @screen-xs-max) {
                    font-size: 10px;
                    margin-left: 10px;
                }

                @media (min-width: @screen-sm-min) {
                    font-size: 16px;
                    letter-spacing: .15em;
                }
            }
        }
    }

    .site-search {
        display: flex;

        @media (max-width: @screen-xs-max) {
            padding: 10px @grid-gutter-width;
            border-top: 1px solid @border-color;
            order: 9999;
            flex: 1 1 100%;
        }

        @media (max-width: @screen-xxs-max) {
            padding: 10px (@grid-gutter-width / 2);
        }

        @media (min-width: @screen-sm-min) {
            flex: 1 1 0%;
            justify-content: center;
            padding: 0 (@grid-gutter-width / 2);
        }

        form {
            @media (max-width: @screen-xs-max) {
                width: 100%;
            }

            input {
                width: 100%;

                @media (min-width: @screen-sm-min) {
                    width: auto;
                }

                @media (min-width: @screen-lg-min) {
                    width: 275px;
                }
            }
        }
    }

    .header-actions {
        flex: 1 0 0%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-left: (@grid-gutter-width / 2);
        justify-content: flex-end;

        @media (max-width: @screen-xs-max) {
            flex: 1 1 0%;
        }

        .nav-basket {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            flex: 1 0 auto;
            justify-content: flex-end;
        }

        .user-nav {
            margin-left: 20px;

            @media (max-width: @screen-sm-max) {
                display: none;
            }
        }

        .language-chooser {
            margin-left: 20px;

            @media (max-width: @screen-sm-max) {
                display: none;
            }
        }

        .mobile-nav-toggler {
            padding-left: 20px;

            @media (max-width: @screen-xxs-max) {
                padding-right: (@grid-gutter-width / 2);
            }

            @media (max-width: @screen-xs-max) {
                padding-top: (@grid-gutter-width / 2);
                padding-bottom: (@grid-gutter-width / 2);
            }

            @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
                padding-right: @grid-gutter-width;
            }

            @media (min-width: @screen-md-min) {
                display: none;
            }
        }
    }
}
