.basket-methods {
    .radio-options {
        ul {
            padding: 0;
            margin: 0 0 10px 0;
            overflow: hidden;
            border: 1px solid @border-color;
            border-radius: @border-radius-base;

            li {
                display: block;
                border-top: 1px solid @border-color;
                background: @white;

                &:first-child {
                    border: none;
                }

                &:hover {
                    background: rgba(255,255,255,0.5);
                }

                &.is-disabled {
                    opacity: 0.7;
                    cursor: not-allowed;

                    &:hover {
                        background: @white;
                    }

                    label {
                        cursor: not-allowed;
                        pointer-events: none;
                    }

                    .method-price {
                        color: inherit;
                    }
                }
            }
        }

        label {
            display: block;
            padding: @grid-gutter-width/2 @grid-gutter-width/2 @grid-gutter-width/2 @grid-gutter-width*1.5;
            margin: 0;
            position: relative;
            transition: all .15s;
            font-weight: 600;
            cursor: pointer;
        }

        input[type="radio"] {
            position: absolute;
            top: @grid-gutter-width / 2;
            left: @grid-gutter-width / 2;
        }

        .radio-name span {
            white-space: nowrap;
        }

        .description {
            display: block;
            margin-top: 5px;
            font-style: italic;
            opacity: 0.8;
            font-size: 90%;
        }

        .zip-codes {
            display: block;
            margin-top: 5px;
            font-style: italic;
            font-size: 90%;
        }

        .disabled-info {
            font-weight: 600;
            margin-top: 5px;
            font-style: italic;
        }
    }
}
