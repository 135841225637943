.prescription-order-phases {
    ul {
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;
    }

    li {
        display: block;
        position: relative;
        padding: (@grid-gutter-width / 2) 0;
        flex: 0 0 percentage(1/6, 5);
        cursor: pointer;

        &:after {
            content: "";
            z-index: 1;
            display: block;
            position: absolute;
            background: @border-color-dark;
            transform-origin: 0 0;
            height: 3px;
            left: 0;
            right: 0;
            top: 33px;
            transform: translate(50%, 0%);

            @media (max-width: @screen-xs-max) {
                top: 30px;
                height: 2px;
            }
        }

        &:nth-child(even) {
            @media (max-width: @screen-xs-max) {
                .name {
                    top: auto;
                    bottom: 0;
                }
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        &:hover:not(.disabled):not(.active-phase) {
            .number {
                color: @text-muted-dark;
                border-color: darken(@border-color-dark, 10%);
            }

            .name {
                color: @text-color;
            }
        }

        &.active-phase {
            .number {
                color: @text-color;
                border-color: @brand-primary;
                background: #fff;
            }

            .name {
                color: @text-color;
            }
        }

        &.errors-in-phase {
            .number {
                border-color: @brand-danger !important;
                color: @text-color;
            }
        }

        &.disabled {
            cursor: default;

            &:after {
                background: lighten(@border-color-dark, 10%);
            }

            .number {
                border-color: lighten(@border-color-dark, 10%);
                color: lighten(@text-muted, 5%);
            }

            .name {
                color: @text-muted;
            }
        }

        .number {
            position: relative;
            font-weight: bold;
            display: block;
            border: 3px solid @border-color-dark;
            height: 40px;
            width: 40px;
            line-height: 34px;
            border-radius: 50%;
            color: @text-muted;
            background: #fff;
            text-align: center;
            z-index: 2;
            margin: 0 auto;

            @media (max-width: @screen-xs-max) {
                border-width: 2px;
                height: 30px;
                width: 30px;
                line-height: 26px;
                font-size: @font-size-small;
            }

            @media (min-width: @screen-sm-min) {
                margin: 0 auto 5px auto;
            }
        }

        .name {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-size: @font-size-small;
            color: @text-muted-dark;
            font-weight: bold;
            padding: 0px 5px;

            @media (max-width: @screen-xs-max) {
                font-weight: normal;
                font-size: (@font-size-small * 0.75);
            }
        }
    }
}
