.prescription-search {
    margin: @grid-gutter-width 0;
    position: relative;

    .ajax-search-results {
        position: absolute;
        background-color: #fff;
        padding: 10px;
        z-index: 100;
        border: 1px solid @border-color-dark;
        width: 100%;
        max-height: 300px;
        overflow: auto;
        border-radius: @border-radius-base;
        .base-box-shadow;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                cursor: pointer;
                padding: 8px 15px;
                color: lighten(@text-color, 20%);

                &:hover {
                    background: @brand-gray-light;
                    color: @text-color;
                }

                & + li {
                    border-top: 1px solid @border-color;
                }
            }
        }
    }
}

.prescription-secondary-field {
    .prescription-collapse-textarea {
        margin-top: (@grid-gutter-width / 2);
        display: none;
    }
}

.prescription-table {
    .image {
        width: 45px !important;

        img {
            max-width: 100%;
        }

        .badge.medicine {
            margin-top: 7px !important;

            @media (max-width: @screen-xs-max) {
                font-size: 9px;
                padding: 1px 4px;
            }
        }
    }

    .delete-column {
        width: 40px !important;

        button.delete {
            .btn;
            .btn-xs;
            .btn-gray-outline;

            i {
                .fa();
                .fa-fw();

                &:before {
                    content: @fa-var-trash-o;
                }
            }

            span {
                @media (max-width: @screen-xs-max) {
                    display: none;
                }
            }
        }
    }

    .delete-column,
    .image {
        @media (min-width: @screen-sm-min) {
            width: 1% !important;
        }
    }

    .product-extra-details {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
