.admin-toolbar {
    background: #000;
    color: #fff;
    height: @admin-toolbar-height;
    line-height: @admin-toolbar-height;
    font-size: @font-size-small;
    font-weight: @headings-font-weight;
    z-index: @zindex-navbar;

    @media (min-width: @screen-md-min) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    a {
        color: #fff;
    }
}
