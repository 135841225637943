.badge {
    font-size: @font-size-small;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    margin: 3px;
    color: @white;
    background: @text-color;
    padding: 1px 8px;
    border-radius: 20px;

    &.badge-orange {
        background: @brand-orange;
    }

    &.badge-blue {
        background: @brand-info;
    }
}
