.basket-main-content {
    .title-row {
        @media (min-width: @screen-xs-min) {
            .flex-row;
        }

        .section-title {
            @media (min-width: @screen-xs-min) {
                flex: 1 1 66.666666%;
                padding: 0px 15px;
            }
        }

        .cancel-order-btn-wrap {
            margin-bottom: @grid-gutter-width;

            @media (min-width: @screen-xs-min) {
                flex: 0 0 33.333333%;
                padding: 0px 15px;
                text-align: right;
            }
        }
    }

    .phase-hidden {
        display: none;
    }
}
