.category-details {
    margin-bottom: @grid-gutter-width;

    .category-image {
        margin-bottom: @grid-gutter-width;
    }

    .category-description {
        @media (min-width: @screen-md-min) {
            line-height: 2;
            font-size: @font-size-large;
        }
    }
}

ul.subcategories-list {
    list-style: none;
    padding: 0 0 (@grid-gutter-width / 2) 0;
    margin: 0;

    li {
        display: inline-block;
        margin: 5px 10px 5px 0;

        a {
            display: inline-block;
            font-weight: 700;
            color: @link-color;
            border: 2px solid @link-color;
            padding: 3px 12px;
            border-radius: 20px;
            white-space: nowrap;
            position: relative;
            font-size: 13px;
            transition: all .1s;

            &:hover, &:focus {
                border-color: @text-color;
                color: @text-color;
                text-decoration: none;

                .product-count {
                    color: @text-color;
                }
            }

            .product-count {
                display: inline-block;
                color: darken(@brand-gray, 20%);
                transition: all .1s;
            }
        }
    }
}

.category-sort {
    display: flex;
    align-items: center;
    margin-top: @grid-gutter-width;

    @media (min-width: @screen-md-min) {
        justify-content: flex-end;
    }

    .sort-label,
    .form-group {
        margin: 0 (@grid-gutter-width / 2) (@grid-gutter-width / 2) 0;

        @media (min-width: @screen-md-min) {
            margin: 0 0 (@grid-gutter-width / 2) (@grid-gutter-width / 2);
        }
    }

    .dropdown {
        display: block;
    }

    .dropdown-menu {
        @media (min-width: @screen-md-min) {
            left: auto;
            right: 0;
        }
    }
}

.product-list {
    padding: (@grid-gutter-width / 2) 0;

    .product-row {
        padding: (@grid-gutter-width / 2);
        padding-bottom: (@grid-gutter-width / 1.5);

        @media (max-width: @screen-xs-max) {
            padding-left: (@grid-gutter-width + 75);
        }

        @media (min-width: @screen-sm-min) {
            padding: @grid-gutter-width;
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
        }

        & + .product-row {
            margin-top: @grid-gutter-width;
        }

        .image-col {
            @media (max-width: @screen-xs-max) {
                position: absolute;
                left: (@grid-gutter-width / 2);
                top: (@grid-gutter-width / 2);
                width: 75px;
            }

            @media (min-width: @screen-sm-min) {
                width: 100px;
                flex: 0 0 auto;
            }

            @media (min-width: @screen-lg-min) {
                width: 150px;
            }

            > a {
                transition: all .1s;

                &:hover, &:focus {
                    opacity: 0.75;
                }
            }
        }

        .detail-col {
            @media (min-width: @screen-sm-min) {
                flex: 1 1 auto;
                padding: 0 @grid-gutter-width;
            }

            .product-name {
                display: block;
                color: @text-color;
                font-weight: 600;
                font-size: @font-size-large;
                margin-bottom: (@grid-gutter-width / 2);
                transition: all .1s;

                &:hover, &:focus {
                    text-decoration: none;
                    color: @link-color;
                }

                .name {
                    display: inline-block;
                    margin-right: 5px;

                    @media (max-width: @screen-xs-max) {
                        line-height: 1.1;
                    }
                }

                .badge-container {
                    display: inline-block;
                }
            }

            .product-description {
                color: @text-muted-dark;
                margin-bottom: (@grid-gutter-width / 2);

                @media (min-width: @screen-sm-min) {
                    line-height: 2;
                }
            }

            .read-more {
                display: block;
                font-weight: 700;
                transition: all .1s;
                line-height: 1.2;

                @media (max-width: @screen-xs-max) {
                    display: none;
                }

                &:hover, &:focus {
                    color: @link-hover-color;
                    text-decoration: none;

                    .svg-icon {
                        fill: @link-hover-color;
                    }
                }

                .svg-icon {
                    fill: @link-color;
                    width: 16px;
                    height: 16px;
                    vertical-align: bottom;
                    margin-left: 3px;
                    transition: all .1s;
                }
            }

            .supply-disruption {
                margin-top: 5px;
                color: @brand-danger;
                font-style: italic;
                font-weight: 700;
            }
        
        }

        .buying-col {
            @media (max-width: @screen-xs-max) {
                margin-top: (@grid-gutter-width / 2);
            }

            @media (min-width: @screen-sm-min) {
                text-align: right;
                flex: 0 1 350px;
                display: flex;
                flex-flow: column wrap;
                align-content: flex-end;
            }

            .price-basket-wrap {
                @media (max-width: @screen-xs-max) {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: flex-end;
                }

                .price-wrap {
                    @media (max-width: @screen-xs-max) {
                        flex: 1 0 0%;
                        white-space: nowrap;
                        margin: 5px 15px 5px 0;
                    }

                    .price {
                        font-size: @font-size-h3;
                    }

                    .save-amount {
                        font-size: @font-size-small;
                    }
                }

                .basket-form {
                    @media (max-width: @screen-xs-max) {
                        flex: 0 0 0%;

                        button {
                            .btn-sm();
                        }
                    }

                    @media (min-width: @screen-sm-min) {
                        margin-top: (@grid-gutter-width / 2);
                        margin-top: @grid-gutter-width;
                    }
                }
            }

            .stock-count {
                align-self: flex-end;
                margin-top: (@grid-gutter-width / 2);
                color: @text-muted-dark;
                line-height: 1.1;
                font-size: @font-size-small;

                &:before {
                    content: "";
                    width: 7px;
                    height: 7px;
                    display: inline-block;
                    margin-right: 3px;
                    border-radius: 50%;
                    background: @text-muted;
                }

                &.in-stock:before {
                    background: @brand-success;
                }

                &.out-of-stock:before {
                    background: @brand-danger;
                }
            }
        }
    }
}
