.product-detail {
    .product-info {
        position: relative;
        margin-top: @grid-gutter-width;

        @media (min-width: @screen-sm-min) {
            margin-top: (@grid-gutter-width * 2);
        }

        @media (min-width: @screen-xl-min) {
            padding-left: (300 + @grid-gutter-width);
            padding-right: (270 + @grid-gutter-width);
        }

        .product-name {
            padding-top: (@grid-gutter-width / 2);
            margin-bottom: 0;
            display: block;
            font-weight: 700;
        }

        .badge-container {
            margin-top: @grid-gutter-width;
        }

        .stock-count {
            margin-top: @grid-gutter-width;
            font-size: @font-size-small;
        }

        .absolute-wrapper {
            .clearfix;

            @media (max-width: @screen-lg-max) {
                margin-top: @grid-gutter-width;
            }

            @media (min-width: @screen-xs-min) and (max-width: @screen-lg-max) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
            }
        }

        .image {
            @media (max-width: @screen-xxs-max) {
                margin-bottom: @grid-gutter-width;
            }

            @media (min-width: @screen-xs-min) {
                width: 40%;
                float: left;
            }

            @media (min-width: @screen-xl-min) {
                float: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 270px;
            }
        }

        .order-section {
            padding: 0 (@grid-gutter-width / 2);
            margin-top: (@grid-gutter-width / 2);
            margin-bottom: @grid-gutter-width;
            text-align: center;

            @media (min-width: @screen-xs-min) {
                width: 60%;
                float: left;
                text-align: left;
                padding: 0 0 0 (@grid-gutter-width * 2);
            }

            @media (min-width: @screen-xl-min) {
                float: none;
                position: absolute;
                top: 0;
                right: 0;
                width: 270px;
            }

            .price-wrap {
                > .price {
                    font-size: @font-size-h1;
                    font-weight: 800;
                    display: block;

                    .taxful + .taxless {
                        display: block;
                        margin-top: 5px;
                        font-size: @font-size-base;
                        color: @text-muted;
                    }
                }

                > .normal-price {
                    font-size: @font-size-h4;
                    font-weight: 600;
                }

                .save-amount {
                    margin-top: 15px;
                }

                .units {
                    margin-top: 15px;
                }
            }

            .basket-form {
                margin-top: (@grid-gutter-width / 2);

                .form-group {
                    .clearfix;
                }

                .form-control {
                    width: 125px;

                    @media (max-width: @screen-xxs-max) {
                        margin: 0 auto;
                    }
                }
            }

            .not-orderable {
                margin-top: (@grid-gutter-width / 2);
                color: @brand-danger;
                font-style: italic;
                font-weight: 700;
            }

            .supply-disruption {
                margin-top: 15px;
                color: @brand-danger;
                font-style: italic;
                font-weight: 700;
            }

            .submit-wrap {
                margin-top: @grid-gutter-width;
            }
        }

        .product-info-table {
            margin-top: (@grid-gutter-width * 1.5);

            table > tbody > tr:first-child > td,
            table > tbody > tr:first-child > th {
                border-top: none;
            }

            th {
                font-weight: 600;
            }
        }

        .product-info-sales-marketing {
            margin-top: (@grid-gutter-width * 1.5);
        }
    }

    .product-description {
        margin-top: (@grid-gutter-width * 1.5);

        @media (min-width: @screen-xl-min) {
            padding-left: (300 + @grid-gutter-width);
        }

        @media (min-width: @screen-sm-min) {
            p,
            ul,
            a {
                font-size: @font-size-large;
            }
        }
    }
}
