.product-lift {
    .base-box-shadow();
    border-radius: @border-radius-base;
    width: 100%;
    background: @white;
    position: relative;

    .price-wrap {
        font-size: @font-size-large;

        .taxless {
            font-size: @font-size-base;
            color: @text-muted;
        }
    }
}

.product-image-wrap {
    text-align: center;
    position: relative;

    &:before {
        content: "";
        position: relative;
        padding-top: 100%;
        display: block;
    }

    .product-image {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;

        .no-image {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            color: @text-muted-dark;
            text-align: center;
            transform: translate(0, -50%);
        }
    }
}

.price-wrap {
    line-height: 1.1;

    .price {
        color: @brand-primary;
        font-weight: 800;
    }

    .normal-price {
        color: @text-muted;
        text-decoration: line-through;
        font-weight: normal;
        margin-bottom: 3px;
    }

    .save-amount {
        font-weight: 600;
        margin-top: 5px;
        color: @text-muted-dark;

        span {
            color: @brand-primary;
        }
    }
}
