body.basket.confirm,
body.basket.complete {
    .upper-confirm-button {
        margin-bottom: @grid-gutter-width;

        @media (min-width: @screen-sm-min) {
            text-align: right;
        }
    }

    .basket-products {
        padding-top: (@grid-gutter-width / 2);
        margin-bottom: @grid-gutter-width;

        @media (min-width: @screen-sm-min) {
            padding-top: @grid-gutter-width;
            margin-bottom: (@grid-gutter-width * 1.5);
        }
    }

    .tax-report {
        margin: @grid-gutter-width 0;
        padding-top: (@grid-gutter-width / 2);
    }

    .basket-actions {
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        @media (max-width: @screen-xxs-max) {
            flex-direction: column;
        }

        .edit-basket-link,
        .confirm-button-wrap {
            @media (max-width: @screen-xxs-max) {
                flex-basis: 100% !important;
                margin: (@grid-gutter-width / 2) 0;
                text-align: center;
            }
        }

        .confirm-button-wrap {
            @media (min-width: @screen-xs-min) {
                text-align: right;
            }
        }
    }

    .actions {
        margin-bottom: @grid-gutter-width;
    }
}

.order-confirmation-header {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    @media (max-width: @screen-xxs-max) {
        flex-direction: column;
    }

    .btn {
        font-size: 16px;
    }

    .confirm-button {
        flex-direction: column;
        display: flex;
        text-align: right;
    }

    .confirm-text-upper {
        margin-top: 10px;
    }
}

.confirm-button-wrap {
    flex-direction: column;
    display: flex;
    text-align: right;
}

.confirm-button-lower {
    margin-top: 10px;
}

.edit-basket-link {
    margin-bottom: 20px;
}
